import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import moment from "moment";
import { version } from "../../package.json";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { Dialog } from "vant";
import { jsPDF } from "jspdf";
import { Notify } from "vant";
var Airtable = require("airtable");
var base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLEAPI }).base(process.env.VUE_APP_AIRTABLEBASE);

// const base_api_url = "https://staging-api.yousign.com";
// const viewer_url = "https://staging-app.yousign.com/procedure/sign?members=";
// const headers = {
// 	Authorization: "Bearer 13ff4a3b5877287d73b45767ccd38650",
// 	"Content-Type": "application/json"
// };

const base_api_url = "https://api.yousign.app/v3";
//const viewer_url = "https://webapp.yousign.com/procedure/sign?members=";
const viewer_url = "https://yousign.app/signatures/";
const apiKey = `Fx6hUwGV7swhZ9VrqK33s1yIMZNK7lmQ`; // When it is ready for production, replace it with a production API key
const headers = {
	Authorization: "Bearer Fx6hUwGV7swhZ9VrqK33s1yIMZNK7lmQ",
	"Content-Type": "application/json"
};

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
	key: `appviessmann${version}`,
	storage: window.localStorage,
	modules: ["user", "connected", "lastUpdate"]
});

export default new Vuex.Store({
	state: {
		generation_suspect_pdf: false,
		connected: false,
		lastUpdate: "",
		user: {},
		users: [],
		secteurs: [],
		current_client: {},
		users_ready: false,
		clients: [],
		fetch_client_ended: false,
		suspects: [],
		current_suspect: [],
		clientsStats: [],
		loadClient: false,
		loadSuspect: false,
		formations: [],
		formations_ready: false,
		chartes: [],
		documents: [],
		procedure: "Commencer la signature",
		generator_mode: false,
		message_generation: "",
		current_secteur_client: "",
		current_secteur_stats: "",
		loadClientsSpecial: false,
		loadSuspectsSpecial: false,
		clients_cache: false,
		suspects_cache: false,
		suspects_sap: [],
		current_suspect_sap: {},
		message: [],
		annulation_en_cours: false,
		validations: [],
		stats: [],
		stats_ready: false,
		stats_current_secteur: "",
		super_stats: [],
		lien_statistiques: "",
		CGV_clients: []
	},
	mutations: {
		addCGVClient(state, payload) {
			state.CGV_clients.push(payload);
		},
		addStat(state, payload) {
			state.stats.push(payload);
		},
		reset_fetch_client_ended(state) {
			state.fetch_client_ended = false;
		},
		toggleClient(state) {
			state.clients_cache = !state.clients_cache;
		},
		setSecteurClient(state, payload) {
			state.current_secteur_client = payload;
		},
		setSecteurStatistiques(state, payload) {
			state.current_secteur_stats = payload;
		},
		addUser(state, payload) {
			state.users.push(payload);
		},
		addClient(state, payload) {
			state.clients.push(payload);
		},
		addSuspect(state, payload) {
			state.suspects.push(payload);
		},
		addClientStats(state, payload) {
			state.clientsStats.push(payload);
		},
		setUser(state, payload) {
			state.user = payload;
			state.connected = true;
		},
		updateStoreField(state, payload) {
			state.current_client.fields[payload.field] = payload.value;
			base("clients").update([
				{
					id: state.current_client.id,
					fields: {
						[payload.field]: payload.value
					}
				}
			]);
		},
		updateStatutForATC(state, payload) {
			let current_client = state.clients.find(el => el.id === payload.id);
			current_client.fields.statut = payload.statut;
			current_client.fields.signature_demandee_statut = payload.statut_souhaite || "";

			base("clients").update([
				{
					id: payload.id,
					fields: {
						statut: payload.statut,
						signature_demandee_statut: payload.statut_souhaite,
						signature_demandee: payload.signature_demandee
					}
				}
			]);
		},
		updateStatut(state, payload) {
			let current_client = state.clients.find(el => el.id === payload.id);
			current_client.fields.statut = payload.statut;
			if (current_client.fields["Classification (Matrice)"] === "Silver" && payload.statut_souhaite === "Proactif Gold (MIP)") {
				base("clients").update([
					{
						id: payload.id,
						fields: {
							statut: payload.statut,
							signature_demandee: payload.signature_demandee,
							signature_autorisee: payload.signature_autorisee,
							"Classe de partenaire": payload.statut_souhaite,
							"Objectif visites": 12
						}
					}
				]);
			} else if (current_client.fields["Classification (Matrice)"] === "Basic") {
				if (payload.statut === "Prêt à signer") {
					base("clients").update([
						{
							id: payload.id,
							fields: {
								statut: payload.statut,
								"Cibler en prospect": true,
								signature_demandee: payload.signature_demandee,
								signature_autorisee: payload.signature_autorisee,
								"Classe de partenaire": "Prospect (EK)"
							}
						}
					]);
				} else if (payload.statut === "Refusé") {
					base("clients").update([
						{
							id: payload.id,
							fields: {
								statut: payload.statut,
								"Cibler en prospect": false,
								signature_demandee: payload.signature_demandee,
								signature_autorisee: payload.signature_autorisee,
								"Classe de partenaire": "Basic (PAR)"
							}
						}
					]);
				}
			} else {
				base("clients").update([
					{
						id: payload.id,
						fields: {
							statut: payload.statut,
							signature_demandee: payload.signature_demandee,
							signature_autorisee: payload.signature_autorisee,
							"Classe de partenaire": payload.statut_souhaitee
						}
					}
				]);
			}
		},
		updateSignatureDemande(state, payload) {
			let current_client = state.clients.find(el => el.id === payload.id);
			current_client.fields.signature_demandee_statut = payload.statut;
			base("clients").update([
				{
					id: payload.id,
					fields: {
						signature_demandee_statut: payload.statut
					}
				}
			]);
		},
		updateSuspectField(state, payload) {
			state.current_suspect.fields[payload.field] = payload.value;
			base("base_suspects").update([
				{
					id: state.current_suspect.id,
					fields: {
						[payload.field]: payload.value
					}
				}
			]);
			if (state.current_suspect.fields.statut === "ouvert") {
				base("base_suspects").update([
					{
						id: state.current_suspect.id,
						fields: {
							statut: "qualifie"
						}
					}
				]);
				let found = state.suspects.findIndex(el => el.id === state.current_suspect.id);
				if (found != null || found != undefined) {
					state.suspects[found].fields.statut = "qualifie";
				}
			}
		},
		updateUser(state, payload) {
			state.user.fields[payload.field] = payload.value;
			base("utilisateurs").update([
				{
					id: state.user.id,
					fields: {
						[payload.field]: payload.value
					}
				}
			]);
		},
		addFormation(state, payload) {
			state.formations.push(payload);
		},
		addClientFormation(state, payload) {
			state.current_client.fields.liste_des_formations.push(payload);
		},
		deleteClientFormation(state, payload) {
			state.current_client.fields.liste_des_formations.forEach((el, index) => {
				if (el.id == payload) {
					state.current_client.fields.liste_des_formations.splice(index, 1);
				}
			});
		},
		addCharte(state, payload) {
			state.chartes.push(payload);
		},
		setStatsMenu(state, payload) {
			state.lien_statistiques = payload.fields.value;
		},
		addMessage(state, payload) {
			state.message.push(payload);
		},
		addDocuments(state, payload) {
			state.documents.push(payload);
		},
		updateClientStatus(state, payload) {
			let found = state.clients.findIndex(el => el.id === payload.client);
			if (found != null || found != undefined) {
				state.clients[found].fields.statut = payload.statut;
			}
		},
		updateSuspectStatus(state, payload) {
			let found = state.suspects.findIndex(el => el.id === payload.id);
			if (found != null || found != undefined) {
				state.suspects[found].fields.statut = payload.statut;
			}
		},
		generatorMode(state, payload) {
			state.generator_mode = payload;
		},
		setMessageGeneration(state, payload) {
			state.message_generation = payload;
		},
		setLoadClientSpecial(state, payload) {
			state.loadClientsSpecial = payload;
		},
		addSuspectSAP(state, payload) {
			state.suspects_sap.push(payload);
		},
		reset_currentClient(state) {
			state.current_client = {};
		},
		updateClientCGVStatus(state, payload) {
			let found = state.CGV_clients.findIndex(el => el.id === payload.client.id);
			if (found != -1) {
				state.CGV_clients[found].fields.statut = payload.value;
			}
		},
		updateClientCGVIdProcedure(state, payload) {
			let found = state.CGV_clients.findIndex(el => el.id === payload.client.id);
			if (found != -1) {
				state.CGV_clients[found].fields.id_procedure_signature = payload.value;
			}
		},
		updateClientCGVLienProcedure(state, payload) {
			let found = state.CGV_clients.findIndex(el => el.id === payload.client.id);
			if (found != -1) {
				state.CGV_clients[found].fields.lien_signature = payload.value;
			}
		},
		updateClientCGVDateEnvoi(state, payload) {
			let found = state.CGV_clients.findIndex(el => el.id === payload.client.id);
			if (found != -1) {
				state.CGV_clients[found].fields.date_envoi = payload.value;
			}
		}
	},
	actions: {
		cancelSignature({ state }) {
			return new Promise(resolve => {
				let current_client = state.clients.find(el => el.id === state.current_client.id);
				current_client.fields.statut = "Prêt à signer";
				current_client.fields.id_procedure_signature = "";
				current_client.fields.lien_signature = "";
				current_client.fields.pdf_signe_envoye = false;

				state.current_client.fields.statut = "Prêt à signer";
				state.current_client.fields.id_procedure_signature = "";
				state.current_client.fields.lien_signature = "";
				state.current_client.fields.pdf_signe_envoye = false;

				base("clients").update([
					{
						id: state.current_client.id,
						fields: {
							statut: "Prêt à signer",
							id_procedure_signature: "",
							lien_signature: "",
							pdf_signe_envoye: false
						}
					}
				]);
				resolve();
			});
		},
		copySuspect({ state }, payload) {
			return new Promise((resolve, reject) => {
				base("clients").create(
					[
						{
							fields: {
								Société: payload.suspect.suspect || "",
								"Numéro client": payload.suspect.siret,
								Adresse: payload.suspect.adresse || "",
								"Complément d'adresse": payload.suspect.complement_adresse || "",
								"Code Postal": payload.suspect.code_postal || "",
								Ville: payload.suspect.ville || "",
								"Téléphone fixe": payload.suspect.telephone || "",
								Email: payload.suspect.email || "",
								SIRET: payload.suspect.siret,
								"Secteur commercial": payload.suspect.secteur_commercial,
								"Site internet": payload.suspect.site_internet || "",
								"Nom interlocuteur principal": payload.suspect.nom_interlocuteur || "",
								"Prénom interlocuteur principal": payload.suspect.prenom_interlocuteur || "",
								"Téléphone interlocuteur principal": payload.suspect.telephone_interlocuteur || "",
								"Email interlocuteur principal": payload.suspect.mail_interlocuteur || "",
								"Potentiel du client": parseInt(payload.suspect.potentiel_ca_calcule) || 0,
								"Potentiel en PAC": parseInt(payload.suspect.volume_pac) || 0,
								"Potentiel en CLIM": parseInt(payload.suspect.volume_clim) || 0,
								"Plafond de crédit actuel": parseInt(payload.suspect.avis_credit_conseille) || 0,
								"Base de donnée origine": payload.suspect.base_origine || "",
								PAC: parseInt(payload.suspect.pac) || 0,
								Clim: parseInt(payload.suspect.clim) || 0,
								"Ch.Fioul": parseInt(payload.suspect.fioul) || 0,
								"Ch.Gaz": parseInt(payload.suspect.gaz) || 0,
								MGP: parseInt(payload.suspect.mgp) || 0,
								Solaire: parseInt(payload.suspect.solaire) || 0,
								PV: parseInt(payload.suspect.pv) || 0,
								"Ch.Bois": parseInt(payload.suspect.bois) || 0,
								diffus: parseInt(payload.suspect.diffusion) || 0,
								affaires: parseInt(payload.suspect.affaires) || 0,
								service: parseInt(payload.suspect.services) || 0,
								neufs: parseInt(payload.suspect.neuf) || 0,
								renovation: parseInt(payload.suspect.renovation) || 0,
								particulier: parseInt(payload.suspect.particulier) || 0,
								collectif: parseInt(payload.suspect.collectif) || 0,
								tertiaire: parseInt(payload.suspect.tertiaire) || 0,
								industrie: parseInt(payload.suspect.industrie) || 0,
								"Classification (Matrice)": "Basic",
								"Classe de partenaire": payload.type === "to_basic" ? "Basic (PAR)" : "Prospect (EK)",
								"Cibler en prospect": payload.type === "to_prospect" ? true : false,
								"Objectif visites": payload.type === "to_basic" ? 8 : 24,
								"RGE Eco artisant": payload.suspect.rge_eco_artisan || false,
								"Date de fin Eco artisant": payload.suspect.date_fin_eco_artisant || null,
								"Numéro Eco artisant": payload.suspect.numero_eco_artisant || "",
								"RGE Pro de la performance énergétique": payload.suspect.rge_eco_artisan || false,
								"Date de fin pro de la performance énergétique": payload.suspect.date_fin_eco_artisant || null,
								"Numéro pro de la performance énergétique": payload.suspect.numero_eco_artisant || "",
								"RGE Qualibat": payload.suspect.rge_eco_artisan || false,
								"Date de fin Qualibat": payload.suspect.date_fin_qualibat || null,
								"Numéro Qualibat": payload.suspect.numero_qualibat || "",
								"RGE QualiGaz": payload.suspect.rge_qualigaz || false,
								"Date de fin Qualigaz": payload.suspect.date_fin_qualigaz || null,
								"Numéro Qualigaz": payload.suspect.numero_qualigaz || "",
								"RGE QualiBois": payload.suspect.rge_qualibois || false,
								"Date de fin QualiBois": payload.suspect.date_fin_qualibois || null,
								"Numéro Qualibois": payload.suspect.numero_qualibois || "",
								"RGE QualiPAC": payload.suspect.rge_qualipac || false,
								"Date de fin QualiPAC": payload.suspect.date_fin_qualipac || null,
								"Numéro QualiPAC": payload.suspect.numero_qualipac || "",
								"RGE QualiSol": payload.suspect.rge_qualisol || false,
								"Date de fin QualiSol": payload.suspect.date_fin_qualisol || null,
								"Numéro Qualisol": payload.suspect.numero_qualisol || "",
								"RGE QualiPV": payload.suspect.rge_qualipv || false,
								"Date de fin QualiPV": payload.suspect.date_fin_qualipv || null,
								"Numéro QualiPV": payload.suspect.numero_qualipv || "",
								"RGE QualiCET": payload.suspect.rge_qualicet || false,
								"Date de fin QualiCET": payload.suspect.date_fin_qualicet || null,
								"Numéro QualiCET": payload.suspect.numero_qualicet || "",
								"RGE Qualifelec": payload.suspect.qualifelec || false,
								"Date de fin Qualifelec": payload.suspect.date_fin_qualifelec || null,
								"Numéro Qualifelec": payload.suspect.numero_qualifelec || "",
								"Habilitation fluide": payload.suspect.habilitation_fluide || false,
								"Date de fin habilitation fluide": payload.suspect.date_fin_habilitation_fluide || null,
								"Numéro habilitation fluide": payload.suspect.numero_habilitation_fluide || "",
								"Habilitation électrique": payload.suspect.habilitation_electricite || false,
								"Date de fin habilitation électrique": payload.suspect.habilitation_electricite || null,
								"Numéro habilitation électrique": payload.suspect.numero_habilitation_electricite || "",
								statut: "Signé",
								last_open_tab: 0
							}
						}
					],
					function(err, records) {
						if (err) {
							console.error(err);
							reject(err);
						}
						resolve(records);
					}
				);
			});
		},
		connectUser({ state }, payload) {
			state.user = payload;
			state.connected = true;
			base("utilisateurs").update([
				{
					id: payload.id,
					fields: {
						derniere_connexion: Date.now()
					}
				}
			]);
		},
		getUsers({ state, commit }) {
			return new Promise(resolve => {
				state.users_ready = false;
				state.users = [];
				base("utilisateurs")
					.select({
						maxRecords: 999,
						view: "liste_des_utilisateurs"
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach(record => {
								commit("addUser", record);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								console.error(err);
								return;
							}
							resolve();
							state.users_ready = true;
							state.loadClientsSpecial = false;
						}
					);
			});
		},
		disconnect({ state }) {
			state.user = {};
			state.connected = false;
		},
		getATC({ state }) {
			return new Promise(resolve => {
				resolve();
			});
		},
		getSuspects({ state, commit }, payload) {
			state.current_suspect = {};
			state.generator_mode = false;
			let go = false;
			if (!payload.force && state.lastUpdate.length > 0 && state.clients.length > 0) {
				let now = new moment();
				let start = new moment(state.lastUpdate);
				let duration = moment.duration(now.diff(start)).as("hours");
				if (duration >= 1) {
					go = true;
				}
			} else {
				go = true;
			}
			if (go) {
				state.suspects = [];
				let secteur = state.user.fields.secteur;
				if (state.user.fields.role != "atc" && state.current_secteur_client.length > 0) {
					secteur = state.current_secteur_client;
				}
				return new Promise((resolve, reject) => {
					state.lastUpdate = new moment().format();
					base("base_suspects")
						.select({
							maxRecords: 9999,
							view: "base_suspects",
							pageSize: 40,
							filterByFormula: "({secteur_commercial} = " + secteur + ")",
							sort: [{ field: "classement", direction: "desc" }],
							fields: [
								"suspect",
								"statut",
								"rating",
								"secteur_commercial",
								"classement",
								"potentiel_ca_calcule",
								"base_origine",
								"avis_credit_conseille",
								"derniere_modification"
							]
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(record => {
									commit("addSuspect", record);
								});
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									reject(err);
								}
								resolve();
							}
						);
				});
			}
		},
		getClients({ state, commit, dispatch }, payload) {
			state.fetch_client_ended = false;
			state.current_client = {};
			state.generator_mode = false;
			let go = false;
			if (!payload.force && state.lastUpdate.length > 0 && state.clients.length > 0) {
				let now = new moment();
				let start = new moment(state.lastUpdate);
				let duration = moment.duration(now.diff(start)).as("hours");
				if (duration >= 1) {
					go = true;
				}
			} else {
				go = true;
			}
			if (go) {
				state.clients = [];
				let secteur = state.user.fields.secteur;
				if (state.user.fields.role != "atc" && state.current_secteur_client.length > 0) {
					secteur = state.current_secteur_client;
					state.loadClientsSpecial = true;
				}
				return new Promise((resolve, reject) => {
					state.lastUpdate = new moment().format();
					base("clients")
						.select({
							maxRecords: 9999,
							view: "base_clients",
							pageSize: 40,
							filterByFormula: "({Secteur commercial} = " + secteur + ")",
							sort: [{ field: "Société", direction: "asc" }],
							fields: [
								"Numéro client",
								"Nom interlocuteur principal",
								"Prénom interlocuteur principal",
								"Secteur commercial",
								"Société",
								"Classification (Matrice)",
								"Classe de partenaire",
								"statut",
								"id_procedure_signature",
								"Nom signataire",
								"pdf_signe_envoye",
								"Wild Card Proactif",
								"url_pdf_signe",
								"signature_demandee",
								"signature_autorisee",
								"signature_demandee_statut"
							]
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(record => {
									if (record.fields.statut === "Envoyé" && record.fields.id_procedure_signature) {
										dispatch("checkSignatureV3", record.fields["id_procedure_signature"])
											.then(res => {
												if (res.status === "done") {
													record.fields.statut = "Signé";
													base("clients").update([
														{
															id: record.id,
															fields: {
																statut: "Signé"
															}
														}
													]);
													if (!record.fields["url_pdf_signe"]) {
														dispatch("downloadSignedDocumentV3", {
															procedure: record.fields["id_procedure_signature"],
															client: record
														});
													}
												}
												if (res.status === "expired" || res.status === "rejected" || res.status === "declined") {
													record.fields.statut = "Brouillon";
												}
												commit("addClient", record);
												//console.log(res.status);
											})
											.catch(err => {
												console.log(err);
												//commit("addClient", record);
											});
									} else {
										if (record.fields.statut === "Signé" && !record.fields.url_pdf_signe) {
											dispatch("downloadSignedDocumentV3", {
												procedure: record.fields["id_procedure_signature"],
												client: record
											});
										}
										// if (record.fields.statut === "Signé" && !record.fields.pdf_signe_envoye && record.fields.url_pdf_signe) {
										// 	dispatch("sendFinalMail", {
										// 		client: record.id
										// 	});
										// }
										commit("addClient", record);
									}
								});
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									reject(err);
								}
								resolve();
								state.fetch_client_ended = true;
								state.loadClientsSpecial = false;
							}
						);
				});
			}
		},
		getStatsClientForSector({ state }, payload) {
			return new Promise((resolve, reject) => {
				let clients = [];
				if (!payload) {
					reject("Secteur manquant");
				} else {
					let formula = "(AND({Classe de partenaire} != 'Basic (PAR)', {Secteur commercial} =" + payload + "))";
					base("clients")
						.select({
							// Selecting the first 3 records in base_clients:
							pageSize: 20,
							filterByFormula: formula,
							maxRecords: 20000,
							view: "base_clients",
							fields: [
								"Numéro client",
								"Secteur commercial",
								"Classification (Matrice)",
								"Classe de partenaire",
								"statut",
								"Chiffre affaires objectif théorique",
								"Chiffre affaires objectif convenu",
								"Objectif visites",
								"Potentiel en PAC",
								"Potentiel en CLIM"
							]
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(function(record) {
									clients.push(record);
								});

								fetchNextPage();
							},
							function done(err) {
								if (err) {
									reject(err);
									return;
								}
								resolve(clients);
							}
						);
				}
			});
		},
		getStatsSuspectForSector({ state }, payload) {
			return new Promise((resolve, reject) => {
				let suspects = [];
				if (!payload) {
					reject("Secteur manquant");
				} else {
					let formula = "({secteur_commercial} =" + payload + ")";
					base("base_suspects")
						.select({
							maxRecords: 20000,
							view: "base_suspects",
							pageSize: 20,
							filterByFormula: formula,
							fields: ["suspect", "statut"]
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(record => {
									suspects.push(record);
								});
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									reject(err);
								}
								resolve(suspects);
							}
						);
				}
			});
		},
		getStatistiques({ state, commit, dispatch }) {
			state.stats = [];
			if (state.user.fields.role === "atc") {
				Promise.all([
					dispatch("getStatsClientForSector", state.user.fields.secteur.trim()),
					dispatch("getStatsSuspectForSector", state.user.fields.secteur.trim())
				]).then(res => {
					let temp_clients = res[0];
					let temp_suspects = res[1];
					let line = {
						secteur: state.user.fields.secteur.trim(),
						proactif_plus: {
							cibles: 0,
							signes: 0,
							visites: 0,
							ca: 0,
							potentiel_pac: 0,
							potention_clim: 0
						},
						proactif: {
							cibles: 0,
							signes: 0,
							visites: 0,
							ca: 0,
							potentiel_pac: 0,
							potention_clim: 0
						},
						partenaire: {
							cibles: 0,
							signes: 0,
							visites: 0,
							ca: 0,
							potentiel_pac: 0,
							potention_clim: 0
						},
						prospect: {
							cibles: 0,
							signes: 0,
							visites: 0,
							ca: 0,
							potentiel_pac: 0,
							potention_clim: 0
						},
						suspects: {
							total: 0,
							ouverts: 0,
							to_prospect: 0,
							to_basic: 0,
							qualifies: 0,
							doublons_sap: 0,
							doublons_suspect: 0,
							non_interessant: 0
						},
						total: {
							cibles: 0,
							signes: 0,
							visites: 0,
							ca: 0,
							potentiel_pac: 0,
							potention_clim: 0
						}
					};
					temp_clients.forEach(el => {
						if (el.fields.statut != "Brouillon") {
							line.total.cibles++;
							line.total.visites += el.fields["Objectif visites"];
							let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
							if (bbbb == 0) {
								bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
							}
							line.total.ca += bbbb;
							line.total.potentiel_pac += el.fields["Objectif visites"];
							line.total.potention_clim += el.fields["Objectif visites"];
							if (el.fields.statut === "Signé") {
								line.total.signes++;
							}
							if (el.fields["Classe de partenaire"] === "Proactif Platinium (MI+)") {
								line.proactif_plus.cibles++;
								line.proactif_plus.visites += el.fields["Objectif visites"];
								let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
								if (bbbb == 0) {
									bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
								}
								line.proactif_plus.ca += bbbb;
								line.proactif_plus.potentiel_pac += el.fields["Objectif visites"];
								line.proactif_plus.potention_clim += el.fields["Objectif visites"];
								if (el.fields.statut === "Signé") {
									line.proactif_plus.signes++;
								}
							} else if (el.fields["Classe de partenaire"] === "Proactif Gold (MIP)") {
								line.proactif.cibles++;
								line.proactif.visites += el.fields["Objectif visites"];
								let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
								if (bbbb == 0) {
									bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
								}
								line.proactif.ca += bbbb;
								line.proactif.potentiel_pac += el.fields["Objectif visites"];
								line.proactif.potention_clim += el.fields["Objectif visites"];
								if (el.fields.statut === "Signé") {
									line.proactif.signes++;
								}
							} else if (el.fields["Classe de partenaire"] === "Partenaire (MAP)") {
								line.partenaire.cibles++;
								line.partenaire.visites += el.fields["Objectif visites"];
								let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
								if (bbbb == 0) {
									bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
								}
								line.partenaire.ca += bbbb;
								line.partenaire.potentiel_pac += el.fields["Objectif visites"];
								line.partenaire.potention_clim += el.fields["Objectif visites"];
								if (el.fields.statut === "Signé") {
									line.partenaire.signes++;
								}
							} else if (el.fields["Classe de partenaire"] === "Prospect (EK)") {
								line.prospect.cibles++;
								line.prospect.visites += el.fields["Objectif visites"];
								let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
								if (bbbb == 0) {
									bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
								}
								line.prospect.ca += bbbb;
								line.prospect.potentiel_pac += el.fields["Objectif visites"];
								line.prospect.potention_clim += el.fields["Objectif visites"];
								if (el.fields.statut === "Signé") {
									line.prospect.signes++;
								}
							}
						}
					});
					temp_suspects.forEach(el => {
						line.suspects.total++;
						if (el.fields.statut === "ouvert") {
							line.suspects.ouverts++;
						} else if (el.fields.statut === "qualifies") {
							line.suspects.qualifies++;
						} else if (el.fields.statut === "to_prospect") {
							line.suspects.to_prospect++;
						} else if (el.fields.statut === "to_basic") {
							line.suspects.to_basic++;
						} else if (el.fields.statut === "doublon_sap") {
							line.suspects.doublons_sap++;
						} else if (el.fields.statut === "doublon_suspect") {
							line.suspects.doublons_suspect++;
						} else if (el.fields.statut === "a_supprimer") {
							line.suspects.non_interessant++;
						}
					});
					commit("addStat", line);
				});
			} else if (state.user.fields.role === "administrateur" || state.user.fields.role === "dr" || state.user.fields.role === "rrv") {
				dispatch("getUsers").then(() => {
					// Je parcours tous les DR pour les admins
					let dr = [];
					let rrv = [];
					if (state.user.fields.role === "administrateur") {
						dr = state.users.filter(item => item.fields.role === "dr");
						dr.forEach(el => {
							el.rrv = state.users.filter(
								item2 => item2.fields.role === "rrv" && item2.fields.dr && item2.fields.dr.trim() === el.fields.secteur.trim()
							);
							el.rrv.forEach(row => {
								row.atc = state.users.filter(
									item3 => item3.fields.role === "atc" && item3.fields.rrv && item3.fields.rrv.trim() === row.fields.secteur.trim()
								);
							});
						});
					}

					if (state.user.fields.role === "dr") {
						dr = [state.user];
						dr.forEach(el => {
							el.rrv = state.users.filter(
								item2 => item2.fields.role === "rrv" && item2.fields.fr && item2.fields.dr.trim() === el.fields.secteur.trim()
							);
							el.rrv.forEach(row => {
								row.atc = state.users.filter(
									item3 => item3.fields.role === "atc" && item3.fields.rrv && item3.fields.rrv.trim() === row.fields.secteur.trim()
								);
							});
						});
					}

					if (state.user.fields.role === "rrv") {
						rrv = [state.user];
						rrv.forEach(el => {
							el.atc = state.users.filter(item => item.fields.role === "atc" && item.fields.rrv && item.fields.rrv.trim() === el.fields.secteur.trim());
						});
						console.log(rrv);
					}

					if ((state.user.fields.role === "administrateur" || state.user.fields.role === "dr") && dr.length > 0) {
						dr.forEach((item, index) => {
							setTimeout(() => {
								item.stats = {
									secteur: item.fields.secteur.trim(),
									proactif_plus: {
										cibles: 0,
										signes: 0,
										visites: 0,
										ca: 0,
										potentiel_pac: 0,
										potention_clim: 0
									},
									proactif: {
										cibles: 0,
										signes: 0,
										visites: 0,
										ca: 0,
										potentiel_pac: 0,
										potention_clim: 0
									},
									partenaire: {
										cibles: 0,
										signes: 0,
										visites: 0,
										ca: 0,
										potentiel_pac: 0,
										potention_clim: 0
									},
									prospect: {
										cibles: 0,
										signes: 0,
										visites: 0,
										ca: 0,
										potentiel_pac: 0,
										potention_clim: 0
									},
									suspects: {
										total: 0,
										ouverts: 0,
										to_prospect: 0,
										to_basic: 0,
										qualifies: 0,
										doublons_sap: 0,
										doublons_suspect: 0,
										non_interessant: 0
									},
									total: {
										cibles: 0,
										signes: 0,
										visites: 0,
										ca: 0,
										potentiel_pac: 0,
										potention_clim: 0
									}
								};
								item.rrv.forEach((item2, index2) => {
									setTimeout(() => {
										item2.atc.forEach((item3, index3) => {
											item2.stats = {
												secteur: item2.fields.secteur.trim() + " - " + item2.fields.prenom + " " + item2.fields.nom,
												proactif_plus: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												proactif: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												partenaire: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												prospect: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												suspects: {
													total: 0,
													ouverts: 0,
													to_prospect: 0,
													to_basic: 0,
													qualifies: 0,
													doublons_sap: 0,
													doublons_suspect: 0,
													non_interessant: 0
												},
												total: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												}
											};
											setTimeout(() => {
												Promise.all([
													dispatch("getStatsClientForSector", item3.fields.secteur.trim()),
													dispatch("getStatsSuspectForSector", item3.fields.secteur.trim())
												]).then(res => {
													let temp_clients = res[0];
													let temp_suspects = res[1];
													let line = {
														secteur: item3.fields.secteur.trim() + " - " + item3.fields.prenom + " " + item3.fields.nom,
														proactif_plus: {
															cibles: 0,
															signes: 0,
															visites: 0,
															ca: 0,
															potentiel_pac: 0,
															potention_clim: 0
														},
														proactif: {
															cibles: 0,
															signes: 0,
															visites: 0,
															ca: 0,
															potentiel_pac: 0,
															potention_clim: 0
														},
														partenaire: {
															cibles: 0,
															signes: 0,
															visites: 0,
															ca: 0,
															potentiel_pac: 0,
															potention_clim: 0
														},
														prospect: {
															cibles: 0,
															signes: 0,
															visites: 0,
															ca: 0,
															potentiel_pac: 0,
															potention_clim: 0
														},
														suspects: {
															total: 0,
															ouverts: 0,
															to_prospect: 0,
															to_basic: 0,
															qualifies: 0,
															doublons_sap: 0,
															doublons_suspect: 0,
															non_interessant: 0
														},
														total: {
															cibles: 0,
															signes: 0,
															visites: 0,
															ca: 0,
															potentiel_pac: 0,
															potention_clim: 0
														}
													};
													temp_clients.forEach(el => {
														if (el.fields.statut != "Brouillon") {
															item.stats.total.cibles++;
															item2.stats.total.cibles++;
															line.total.cibles++;
															item.stats.total.visites += el.fields["Objectif visites"] || 0;
															item2.stats.total.visites += el.fields["Objectif visites"] || 0;
															line.total.visites += el.fields["Objectif visites"] || 0;
															let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
															if (bbbb == 0) {
																bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
															}

															item.stats.total.ca += bbbb;
															item2.stats.total.ca += bbbb;
															line.total.ca += bbbb;

															item.stats.total.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
															item2.stats.total.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
															line.total.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

															item.stats.total.potention_clim += el.fields["Potentiel en CLIM"] || 0;
															item2.stats.total.potention_clim += el.fields["Potentiel en CLIM"] || 0;
															line.total.potention_clim += el.fields["Potentiel en CLIM"] || 0;
															if (el.fields.statut === "Signé") {
																item.stats.total.signes++;
																item2.stats.total.signes++;
																line.total.signes++;
															}
															if (el.fields["Classe de partenaire"] === "Proactif Platinium (MI+)") {
																item.stats.proactif_plus.cibles++;
																item2.stats.proactif_plus.cibles++;
																line.proactif_plus.cibles++;

																item.stats.proactif_plus.visites += el.fields["Objectif visites"] || 0;
																item2.stats.proactif_plus.visites += el.fields["Objectif visites"] || 0;
																line.proactif_plus.visites += el.fields["Objectif visites"] || 0;
																let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
																if (bbbb == 0) {
																	bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
																}

																item.stats.proactif_plus.ca += bbbb;
																item2.stats.proactif_plus.ca += bbbb;
																line.proactif_plus.ca += bbbb;

																item.stats.proactif_plus.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																item2.stats.proactif_plus.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																line.proactif_plus.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

																item.stats.proactif_plus.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																item2.stats.proactif_plus.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																line.proactif_plus.potention_clim += el.fields["Potentiel en CLIM"] || 0;

																if (el.fields.statut === "Signé") {
																	item.stats.proactif_plus.signes++;
																	item2.stats.proactif_plus.signes++;
																	line.proactif_plus.signes++;
																}
															} else if (el.fields["Classe de partenaire"] === "Proactif Gold (MIP)") {
																item.stats.proactif.cibles++;
																item2.stats.proactif.cibles++;
																line.proactif.cibles++;

																item.stats.proactif.visites += el.fields["Objectif visites"] || 0;
																item2.stats.proactif.visites += el.fields["Objectif visites"] || 0;
																line.proactif.visites += el.fields["Objectif visites"] || 0;
																let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
																if (bbbb == 0) {
																	bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
																}

																item.stats.proactif.ca += bbbb;
																item2.stats.proactif.ca += bbbb;
																line.proactif.ca += bbbb;

																item.stats.proactif.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																item2.stats.proactif.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																line.proactif.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

																item.stats.proactif.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																item2.stats.proactif.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																line.proactif.potention_clim += el.fields["Potentiel en CLIM"] || 0;

																if (el.fields.statut === "Signé") {
																	item.stats.proactif.signes++;
																	item2.stats.proactif.signes++;
																	line.proactif.signes++;
																}
															} else if (el.fields["Classe de partenaire"] === "Partenaire (MAP)") {
																item.stats.partenaire.cibles++;
																item2.stats.partenaire.cibles++;
																line.partenaire.cibles++;

																item.stats.partenaire.visites += el.fields["Objectif visites"] || 0;
																item2.stats.partenaire.visites += el.fields["Objectif visites"] || 0;
																line.partenaire.visites += el.fields["Objectif visites"] || 0;
																let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
																if (bbbb == 0) {
																	bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
																}

																item.stats.partenaire.ca += bbbb;
																item2.stats.partenaire.ca += bbbb;
																line.partenaire.ca += bbbb;

																item.stats.partenaire.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																item2.stats.partenaire.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																line.partenaire.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

																item.stats.partenaire.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																item2.stats.partenaire.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																line.partenaire.potention_clim += el.fields["Potentiel en CLIM"] || 0;

																if (el.fields.statut === "Signé") {
																	item.stats.partenaire.signes++;
																	item2.stats.partenaire.signes++;
																	line.partenaire.signes++;
																}
															} else if (el.fields["Classe de partenaire"] === "Prospect (EK)") {
																item.stats.prospect.cibles++;
																item2.stats.prospect.cibles++;
																line.prospect.cibles++;

																item.stats.prospect.visites += el.fields["Objectif visites"] || 0;
																item2.stats.prospect.visites += el.fields["Objectif visites"] || 0;
																line.prospect.visites += el.fields["Objectif visites"] || 0;
																let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
																if (bbbb == 0) {
																	bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
																}

																item.stats.prospect.ca += bbbb;
																item2.stats.prospect.ca += bbbb;
																line.prospect.ca += bbbb;

																item.stats.prospect.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																item2.stats.prospect.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
																line.prospect.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

																item.stats.prospect.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																item2.stats.prospect.potention_clim += el.fields["Potentiel en CLIM"] || 0;
																line.prospect.potention_clim += el.fields["Potentiel en CLIM"] || 0;

																if (el.fields.statut === "Signé") {
																	item.stats.partenaire.signes++;
																	item2.stats.partenaire.signes++;
																	line.partenaire.signes++;
																}
															}
														}
													});
													temp_suspects.forEach(el => {
														line.suspects.total++;
														if (el.fields.statut === "ouvert") {
															item.stats.suspects.ouverts++;
															item2.stats.suspects.ouverts++;
															line.suspects.ouverts++;
														} else if (el.fields.statut === "qualifies") {
															item.stats.suspects.qualifies++;
															item2.stats.suspects.qualifies++;
															line.suspects.qualifies++;
														} else if (el.fields.statut === "to_prospect") {
															item.stats.suspects.to_prospect++;
															item2.stats.suspects.to_prospect++;
															line.suspects.to_prospect++;
														} else if (el.fields.statut === "to_basic") {
															item.stats.suspects.to_basic++;
															item2.stats.suspects.to_basic++;
															line.suspects.to_basic++;
														} else if (el.fields.statut === "doublon_sap") {
															item.stats.suspects.doublons_sap++;
															item2.stats.suspects.doublons_sap++;
															line.suspects.doublons_sap++;
														} else if (el.fields.statut === "doublon_suspect") {
															item.stats.suspects.doublons_suspect++;
															item2.stats.suspects.doublons_suspect++;
															line.suspects.doublons_suspect++;
														} else if (el.fields.statut === "a_supprimer") {
															item.stats.suspects.non_interessant++;
															item2.stats.suspects.non_interessant++;
															line.suspects.non_interessant++;
														}
													});
													item3.stats = line;
													state.super_stats = [...dr];
												});
											}, index3 * 200);
										});
									}, index2 * 2000);
								});
							}, index * 5000);
						});
					} else if (state.user.fields.role === "rrv" && rrv.length > 0) {
						rrv.forEach((item, index) => {
							setTimeout(() => {
								item.atc.forEach((item2, index2) => {
									item.stats = {
										secteur: item.fields.secteur.trim(),
										proactif_plus: {
											cibles: 0,
											signes: 0,
											visites: 0,
											ca: 0,
											potentiel_pac: 0,
											potention_clim: 0
										},
										proactif: {
											cibles: 0,
											signes: 0,
											visites: 0,
											ca: 0,
											potentiel_pac: 0,
											potention_clim: 0
										},
										partenaire: {
											cibles: 0,
											signes: 0,
											visites: 0,
											ca: 0,
											potentiel_pac: 0,
											potention_clim: 0
										},
										prospect: {
											cibles: 0,
											signes: 0,
											visites: 0,
											ca: 0,
											potentiel_pac: 0,
											potention_clim: 0
										},
										suspects: {
											total: 0,
											ouverts: 0,
											to_prospect: 0,
											to_basic: 0,
											qualifies: 0,
											doublons_sap: 0,
											doublons_suspect: 0,
											non_interessant: 0
										},
										total: {
											cibles: 0,
											signes: 0,
											visites: 0,
											ca: 0,
											potentiel_pac: 0,
											potention_clim: 0
										}
									};
									setTimeout(() => {
										Promise.all([
											dispatch("getStatsClientForSector", item2.fields.secteur.trim()),
											dispatch("getStatsSuspectForSector", item2.fields.secteur.trim())
										]).then(res => {
											let temp_clients = res[0];
											let temp_suspects = res[1];
											let line = {
												secteur: item2.fields.secteur.trim() + " - " + item2.fields.prenom + " " + item2.fields.nom,
												proactif_plus: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												proactif: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												partenaire: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												prospect: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												},
												suspects: {
													total: 0,
													ouverts: 0,
													to_prospect: 0,
													to_basic: 0,
													qualifies: 0,
													doublons_sap: 0,
													doublons_suspect: 0,
													non_interessant: 0
												},
												total: {
													cibles: 0,
													signes: 0,
													visites: 0,
													ca: 0,
													potentiel_pac: 0,
													potention_clim: 0
												}
											};
											temp_clients.forEach(el => {
												if (el.fields.statut != "Brouillon") {
													item.stats.total.cibles++;
													line.total.cibles++;
													item.stats.total.visites += el.fields["Objectif visites"] || 0;
													line.total.visites += el.fields["Objectif visites"] || 0;
													let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
													if (bbbb == 0) {
														bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
													}
													item.stats.total.ca += bbbb;
													line.total.ca += bbbb;
													item.stats.total.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
													line.total.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
													item.stats.total.potention_clim += el.fields["Potentiel en CLIM"] || 0;
													line.total.potention_clim += el.fields["Potentiel en CLIM"] || 0;
													if (el.fields.statut === "Signé") {
														item.stats.total.signes++;
														line.total.signes++;
													}
													if (el.fields["Classe de partenaire"] === "Proactif Platinium (MI+)") {
														item.stats.proactif_plus.cibles++;
														line.proactif_plus.cibles++;
														item.stats.proactif_plus.visites += el.fields["Objectif visites"] || 0;
														line.proactif_plus.visites += el.fields["Objectif visites"] || 0;
														let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
														if (bbbb == 0) {
															bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
														}

														item.stats.proactif_plus.ca += bbbb;
														line.proactif_plus.ca += bbbb;

														item.stats.proactif_plus.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
														line.proactif_plus.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

														item.stats.proactif_plus.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														line.proactif_plus.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														if (el.fields.statut === "Signé") {
															item.stats.proactif_plus.signes++;
															line.proactif_plus.signes++;
														}
													} else if (el.fields["Classe de partenaire"] === "Proactif Gold (MIP)") {
														item.stats.proactif.cibles++;
														line.proactif.cibles++;

														item.stats.proactif.visites += el.fields["Objectif visites"] || 0;
														line.proactif.visites += el.fields["Objectif visites"] || 0;
														let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
														if (bbbb == 0) {
															bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
														}

														item.stats.proactif.ca += bbbb;
														line.proactif.ca += bbbb;

														item.stats.proactif.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
														line.proactif.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

														item.stats.proactif.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														line.proactif.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														if (el.fields.statut === "Signé") {
															item.stats.proactif.signes++;
															line.proactif.signes++;
														}
													} else if (el.fields["Classe de partenaire"] === "Partenaire (MAP)") {
														item.stats.partenaire.cibles++;
														line.partenaire.cibles++;

														item.stats.partenaire.visites += el.fields["Objectif visites"] || 0;
														line.partenaire.visites += el.fields["Objectif visites"] || 0;
														let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
														if (bbbb == 0) {
															bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
														}

														item.stats.partenaire.ca += bbbb;
														line.partenaire.ca += bbbb;

														item.stats.partenaire.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
														line.partenaire.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

														item.stats.partenaire.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														line.partenaire.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														if (el.fields.statut === "Signé") {
															item.stats.partenaire.signes++;
															line.partenaire.signes++;
														}
													} else if (el.fields["Classe de partenaire"] === "Prospect (EK)") {
														item.stats.prospect.cibles++;
														line.prospect.cibles++;

														item.stats.prospect.visites += el.fields["Objectif visites"] || 0;
														line.prospect.visites += el.fields["Objectif visites"] || 0;
														let bbbb = Number(el.fields["Chiffre affaires objectif convenu"]) || 0;
														if (bbbb == 0) {
															bbbb = Number(el.fields["Chiffre affaires objectif théorique"]) || 0;
														}

														item.stats.prospect.ca += bbbb;
														line.prospect.ca += bbbb;

														item.stats.prospect.potentiel_pac += el.fields["Potentiel en PAC"] || 0;
														line.prospect.potentiel_pac += el.fields["Potentiel en PAC"] || 0;

														item.stats.prospect.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														line.prospect.potention_clim += el.fields["Potentiel en CLIM"] || 0;
														if (el.fields.statut === "Signé") {
															item.stats.prospect.signes++;
															line.prospect.signes++;
														}
													}
												}
											});
											temp_suspects.forEach(el => {
												line.suspects.total++;
												if (el.fields.statut === "ouvert") {
													item.stats.suspects.ouverts++;
													line.suspects.ouverts++;
												} else if (el.fields.statut === "qualifies") {
													item.stats.suspects.qualifies++;
													line.suspects.qualifies++;
												} else if (el.fields.statut === "to_prospect") {
													item.stats.suspects.to_prospect++;
													line.suspects.to_prospect++;
												} else if (el.fields.statut === "to_basic") {
													item.stats.suspects.to_basic++;
													line.suspects.to_basic++;
												} else if (el.fields.statut === "doublon_sap") {
													item.stats.suspects.doublons_sap++;
													line.suspects.doublons_sap++;
												} else if (el.fields.statut === "doublon_suspect") {
													item.stats.suspects.doublons_suspect++;
													line.suspects.doublons_suspect++;
												} else if (el.fields.statut === "a_supprimer") {
													item.stats.suspects.non_interessant++;
													line.suspects.non_interessant++;
												}
											});
											item2.stats = line;
											state.super_stats = [...rrv];
										});
									}, index2 * 200);
								});
							}, index * 2000);
						});
					}
				});
			}
		},
		chooseClient({ state }, payload) {
			state.loadClient = true;
			state.current_client = {};
			document.body.classList.remove("oneclient");
			base("clients").find(payload, function(err, record) {
				record.fields.liste_des_formations = [];
				let formations = record.fields.demande_formation;
				if (err) {
					console.error(err);
					return;
				}
				if (formations) {
					formations.forEach(el => {
						base("demande_formation").find(el, (error, res) => {
							if (error) {
								console.log(err);
								return;
							}
							record.fields.liste_des_formations.push(res);
						});
					});
				}
				state.current_client = record;
				state.loadClient = false;
				document.body.classList.add("oneclient");
			});
		},
		chooseSuspect({ state }, payload) {
			state.loadSuspect = true;
			state.current_suspect = {};
			document.body.classList.remove("oneclient");
			base("base_suspects").find(payload, function(err, record) {
				if (err) {
					console.error(err);
					return;
				}
				state.current_suspect = record;
				state.loadSuspect = false;
				document.body.classList.add("oneclient");
			});
		},
		lastOpenStep({ state }, payload) {
			if (!payload.type) {
				state.current_client.fields.last_open_tab = payload.step;
				base("clients").update([
					{
						id: payload.id,
						fields: {
							last_open_tab: payload.step
						}
					}
				]);
			} else {
				state.current_suspect.fields.last_open_tab = payload.step;
				base("base_suspects").update([
					{
						id: payload.id,
						fields: {
							last_open_tab: payload.step
						}
					}
				]);
			}
		},
		getFormations({ state, commit }) {
			state.formations = [];
			state.formations_ready = false;
			base("formations")
				.select({
					maxRecords: 999,
					view: "liste_des_formations"
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							commit("addFormation", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
						state.formations_ready = true;
					}
				);
		},
		deleteFormation({ commit }, payload) {
			return new Promise((resolve, reject) => {
				base("demande_formation").destroy(payload, function(err) {
					if (err) {
						reject(err);
						return;
					}
					commit("deleteClientFormation", payload);
					resolve("Formation supprimée");
				});
			});
		},
		registerFormation({ commit }, payload) {
			return new Promise((resolve, reject) => {
				base("demande_formation").create(
					[
						{
							fields: {
								client: [payload.client],
								formation: [payload.id],
								"Nom du stagiaire": payload.nom,
								"Prénom du stagiaire": payload.prenom,
								"Email du stagiaire": payload.email,
								"Fonction du stagiaire": payload.fonction,
								"Trimestre souhaité": payload.trimestre,
								"Téléphone du stagiaire": payload.telephone
							}
						}
					],
					function(err, records) {
						if (err) {
							reject(err);
							return;
						}
						commit("addClientFormation", records[0]);
						resolve(records);
					}
				);
			});
		},
		getChartes({ commit }) {
			base("chartes")
				.select({
					maxRecords: 1,
					view: "chartes"
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							commit("addCharte", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		getMessage({ commit }) {
			base("statistique")
				.select({
					maxRecords: 1,
					view: "stats"
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							commit("setStatsMenu", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
			base("message")
				.select({
					maxRecords: 1,
					view: "message"
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							commit("addMessage", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		getDocuments({ commit }) {
			base("documentation")
				.select({
					maxRecords: 1,
					view: "documentation"
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							commit("addDocuments", record);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		beginSignatureCGV({ commit, state }, payload) {
			state.message_generation = "Envoi du contrat vers YouSign...<br />Cette étape peut être longue en fonction de votre débit";
			try {
				commit("updateClientCGVStatus", {
					client: payload.client,
					value: "En cours de signature"
				});
				commit("updateClientCGVIdProcedure", {
					client: payload.client,
					value: payload.id_procedure_signature
				});
				commit("updateClientCGVDateEnvoi", {
					client: payload.client,
					value: new Date()
				});
				base("clients_cgv").update(
					[
						{
							id: payload.client.id,
							fields: {
								statut: "En cours de signature",
								id_procedure_signature: payload.id_procedure_signature,
								date_envoi: new Date()
							}
						}
					],
					function(err, records) {
						if (err) {
							return err;
						}
						return
					}
				);
			} catch (err) {
				return err;
			}
		},
		beginSignature({ commit, state }, payload) {
			state.message_generation = "Envoi du contrat vers YouSign...<br />Cette étape peut être longue en fonction de votre débit";
			let data = JSON.stringify({
				name: "pac_2025_" + payload.numero + ".pdf",
				content: /base64,(.+)/.exec(payload.doc)[1]
			});
			return new Promise((resolve, reject) => {
				// /files must be change by /document
				axios({
					url: base_api_url + "/files",
					method: "post",
					headers: headers,
					data: data
				})
					.then(res => {
						state.message_generation = "Création de la procédure de signature...";
						let position = "298,96,555,157";
						let id_file = res.data.id;
						let procedure = JSON.stringify({
							name: "Signature pour " + payload.nom_client,
							description: "Numéro client : " + payload.numero + " / Date de création : " + res.data.createdAt,
							start: true,
							config: {},
							members: [
								{
									firstname: state.current_client.fields["Prénom signataire"].trim(),
									lastname: state.current_client.fields["Nom signataire"].trim(),
									email: state.current_client.fields["Email signataire"],
									phone: state.current_client.fields["Tél signataire"],
									fileObjects: [
										{
											file: id_file,
											page: 4,
											position: position,
											mention: "Lu et approuvé"
										}
									]
								}
							]
						});
						// /procedures must be change by /Signature request
						axios({
							url: base_api_url + "/procedures",
							method: "post",
							headers: headers,
							data: procedure
						})
							.then(resultat => {
								state.message_generation = "Chargement de la page de signature...";
								commit("updateClientStatus", {
									client: state.current_client.id,
									statut: "Envoyé"
								});
								commit("updateStoreField", {
									value: resultat.data.id,
									field: "id_procedure_signature"
								});
								commit("updateStoreField", {
									value: viewer_url + resultat.data.members[0].id,
									field: "lien_signature"
								});
								commit("updateStoreField", {
									value: "Envoyé",
									field: "statut"
								});
								commit("updateStoreField", {
									value: version,
									field: "app_version"
								});
								resolve(resultat.data);
							})
							.catch(err => {
								alert(err);
								reject(err);
							});
					})
					.catch(err => {
						reject(err);
						console.log(err);
					});
			});
		},
		checkSignature({ state }, payload) {
			// return new Promise((resolve, reject) => {
			// 	axios({
			// 		method: "GET",
			// 		headers: headers,
			// 		url: base_api_url + payload
			// 	})
			// 		.then(res => {
			// 			resolve(res.data);
			// 		})
			// 		.catch(err => {
			// 			reject(err);
			// 		});
			// });
		},
		async checkSignatureV3({ state }, payload) {
			const url = `${base_api_url}/signature_requests/${payload}`;
			const options = {
				method: "GET",
				headers
			};
			try {
				console.log(payload);
				const reponse = await fetch(url, options);
				const data = await reponse.json();
				return data;
			} catch (e) {
				return e;
			}
		},
		sendFinalMail({ state }, payload) {
			base("clients").find(payload.client, function(err, record) {
				if (err) {
					console.error(err);
					return;
				}
				let params = new URLSearchParams();
				let file = record.fields.url_pdf_signe;
				if (file && record.fields.url_pdf_signe[0].url) {
					let client = record.fields["Email signataire"];
					let commercial = record.fields.email_commercial[0];
					let name = "PAC_2025_" + record.fields["Numéro client"].trim() + "-" + record.fields["Société"] + "_" + record.fields["Nom signataire"] + ".pdf";
					params.append("commercial", commercial);
					params.append("client", client);
					params.append("file", record.fields.url_pdf_signe[0].url);
					params.append("filename", name);
					params.append("service", "finalmailcorrectif");
					axios.post("https://dev-serveur.fr/mailjet/viessmann/api.php", params)
						.then(res => {
							base("clients").update([
								{
									id: record.id,
									fields: {
										pdf_signe_envoye: true
									}
								}
							]);
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					console.log("Abandon de l'envoi du mail car il n'existe aucun document à envoyer");
				}
			});
		},
		sendFinalMailCGV({ state }, payload) {
			base("clients_cgv").find(payload.client, function(err, record) {
				if (err) {
					console.error(err);
					return;
				}
				let params = new URLSearchParams();
				let file = record.fields.url_pdf_signe;
				if (file && record.fields.url_pdf_signe[0].url) {
					console.log("ici");
					let name = "CGV_" + record.fields.numero.trim() + "-" + record.fields.societe + "_" + record.fields.nom_signataire + ".pdf";
					params.append("commercial", record.fields.commercial_email[0]);
					params.append("client", record.fields.email_signataire);
					params.append("file", record.fields.url_pdf_signe[0].url);
					params.append("filename", name);
					params.append("service", "finalmailcorrectifcgv");
					console.log("ici 2");
					axios.post("https://dev-serveur.fr/mailjet/viessmann/api.php", params)
						.then(res => {
							base("clients_cgv").update([
								{
									id: record.id,
									fields: {
										pdf_signe_envoye: true
									}
								}
							]);
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					console.log("Abandon de l'envoi du mail car il n'existe aucun document à envoyer");
				}
			});
		},
		downloadSignedDocumentCGV({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					headers: headers,
					url: base_api_url + payload.procedure
				})
					.then(res => {
						console.log("Résultat de YouSign", res);
						axios({
							method: "GET",
							headers: headers,
							url: base_api_url + res.data.files[0].id + "/download"
						})
							.then(r => {
								const linkSource = `data:application/pdf;base64,${r.data}`;
								var byteString;
								if (linkSource.split(",")[0].indexOf("base64") >= 0) byteString = atob(linkSource.split(",")[1]);
								else byteString = unescape(linkSource.split(",")[1]);
								var mimeString = linkSource
									.split(",")[0]
									.split(":")[1]
									.split(";")[0];
								var ia = new Uint8Array(byteString.length);
								for (var i = 0; i < byteString.length; i++) {
									ia[i] = byteString.charCodeAt(i);
								}
								const pdfBlob = new Blob([ia], { type: mimeString });
								const name =
									"CGV_2025_" +
									payload.client.fields.numero.trim() +
									"-" +
									payload.client.fields.societe.trim() +
									"_" +
									payload.client.fields.nom_signataire.trim() +
									".pdf";
								let file = new File([pdfBlob], name, {
									type: "application/pdf"
								});
								const data = new FormData();
								data.append("file", file);
								axios({
									method: "post",
									url: "https://dev-serveur.fr/mailjet/viessmann/file.php",
									data: data
								})
									.then(result => {
										if (result.data != "false") {
											base("clients_cgv").update(
												[
													{
														id: payload.client.id,
														fields: {
															url_pdf_signe: [{ url: result.data }]
														}
													}
												],
												function() {
													if (!payload.client.fields["pdf_signe_envoye"]) {
														dispatch("sendFinalMailCGV", {
															client: payload.client.id
														});
													}
												}
											);
										}
										resolve(result);
									})
									.catch(error => {
										console.log(error);
									});
							})
							.catch(err => {
								reject(err);
							});
					})
					.catch(err => {
						reject(err);
					});
			});
		},
		downloadSignedDocument({ state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					headers: headers,
					url: base_api_url + payload.procedure
				})
					.then(res => {
						console.log("Résultat de YouSign", res);
						axios({
							method: "GET",
							headers: headers,
							url: base_api_url + res.data.files[0].id + "/download"
						})
							.then(r => {
								const linkSource = `data:application/pdf;base64,${r.data}`;
								var byteString;
								if (linkSource.split(",")[0].indexOf("base64") >= 0) byteString = atob(linkSource.split(",")[1]);
								else byteString = unescape(linkSource.split(",")[1]);
								var mimeString = linkSource
									.split(",")[0]
									.split(":")[1]
									.split(";")[0];
								var ia = new Uint8Array(byteString.length);
								for (var i = 0; i < byteString.length; i++) {
									ia[i] = byteString.charCodeAt(i);
								}
								const pdfBlob = new Blob([ia], { type: mimeString });
								const name =
									"PAC_2025_" +
									payload.client.fields["Numéro client"].trim() +
									"-" +
									payload.client.fields["Société"] +
									"_" +
									payload.client.fields["Nom signataire"] +
									".pdf";
								let file = new File([pdfBlob], name, {
									type: "application/pdf"
								});
								const data = new FormData();
								data.append("file", file);
								axios({
									method: "POST",
									url: "https://dev-serveur.fr/mailjet/viessmann/file.php",
									data: data
								})
									.then(result => {
										if (result.data != "false") {
											base("clients").update(
												[
													{
														id: payload.client.id,
														fields: {
															url_pdf_signe: [{ url: result.data }]
														}
													}
												],
												function() {
													if (!payload.client.fields.pdf_signe_envoye) {
														dispatch("sendFinalMail", {
															client: payload.client.id
														});
													}
												}
											);
										}
										resolve(result);
									})
									.catch(error => {
										console.log(error);
									});
							})
							.catch(err => {
								reject(err);
							});
					})
					.catch(err => {
						reject(err);
					});
			});
		},
		async downloadSignedDocumentV3({ state, dispatch }, payload) {
			try {
				const url = `${base_api_url}/signature_requests/${payload.procedure}/documents/download`;
				const options = {
					method: "GET",
					headers: {
						accept: "application/zip, application/pdf",
						authorization: `Bearer ${apiKey}`
					}
				};
				const query = await fetch(url, options);

				if (!query.ok) {
					throw new Error(`Erreur API: ${query.status} - ${query.statusText}`);
				}

				

				const pdfBlob = await query.blob();

				console.log("retour de la signature", pdfBlob);	

				if(!payload.client.fields["url_pdf_signe"]) {
					// Étape 3 : Préparer le fichier pour le téléversement
					const fileName = `PAC_2025_${payload.client.fields["Numéro client"].trim()}-${payload.client.fields["Société"]}_${
						payload.client.fields["Nom signataire"]
					}.zip`;
					const file = new File([pdfBlob], fileName, { type: "application/zip" });

					const data = new FormData();
					data.append("file", file);
					axios({
						method: "POST",
						url: "https://dev-serveur.fr/mailjet/viessmann/file.php",
						data: data
					})
						.then(result => {
							if (result.data != "false") {
								base("clients").update([
									{
										id: payload.client.id,
										fields: {
											url_pdf_signe: [{ url: result.data }]
										}
									}
								]);
							}
							return pdfBlob;
						})
						.catch(error => {
							console.log(error);
						});
					}
				else {
					return pdfBlob;
				}
			} catch (e) {
				return e;
			}
		},
		updateUserPassword({ state }, payload) {
			state.user.fields.mot_de_passe = payload;
			return new Promise(resolve => {
				base("utilisateurs").update([
					{
						id: state.user.id,
						fields: {
							mot_de_passe: payload
						}
					}
				]);
				resolve("OK");
			});
		},
		recoverPassword({ state }, payload) {
			const found = state.users.findIndex(el => el.id === payload.user.id);
			state.users[found].fields.mot_de_passe = payload.password;
			base("utilisateurs").update([
				{
					id: payload.user.id,
					fields: {
						mot_de_passe: payload.password
					}
				}
			]);
		},
		getSecteurs({ state, commit }) {
			state.users = [];
			return new Promise((resolve, reject) => {
				if (state.user.fields.role === "atc") {
					reject("vous êtes ATC");
				} else {
					let secteurs = [];

					base("utilisateurs")
						.select({
							maxRecords: 999,
							view: "liste_des_utilisateurs",
							pageSize: 10,
							filterByFormula: "({role} = 'atc')"
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(record => {
									commit("addUser", record);
									if (state.user.fields.role === "rrv") {
										if (record.fields.rrv === state.user.fields.secteur) {
											secteurs.push(record);
										}
									} else if (state.user.fields.role === "dr") {
										if (record.fields.dr === state.user.fields.secteur) {
											secteurs.push(record);
										}
									} else if (state.user.fields.role === "administrateur") {
										secteurs.push(record);
									}
									state.secteurs = [...secteurs];
								});

								fetchNextPage();
							},
							function done(err) {
								if (err) {
									return;
								}
								resolve(secteurs);
							}
						);
				}
			});
		},
		getSecteursStats({ state, commit }) {
			state.users = [];
			return new Promise((resolve, reject) => {
				if (state.user.fields.role === "atc") {
					reject("vous êtes ATC");
				} else {
					let secteurs = [];
					base("utilisateurs")
						.select({
							maxRecords: 999,
							view: "liste_des_utilisateurs"
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(record => {
									commit("addUser", record);
									if (state.user.fields.role === "rrv") {
										if (record.fields.role === "atc" && record.fields.rrv === state.user.fields.secteur) {
											secteurs.push(record);
										}
									} else if (state.user.fields.role === "dr") {
										if ((record.fields.role === "atc" || record.fields.role === "rrv") && record.fields.dr === state.user.fields.secteur) {
											secteurs.push(record);
										}
									} else if (state.user.fields.role === "administrateur") {
										if (record.fields.role === "atc" || record.fields.role === "rrv" || record.fields.role === "dr") {
											secteurs.push(record);
										}
									}
								});
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									console.error(err);
									return;
								}
								resolve(secteurs);
							}
						);
				}
			});
		},
		getCSV({ state }, payload) {
			console.log(state.user.fields.name);
			return new Promise((resolve, reject) => {
				let elements = [];
				base("base_suspects")
					.select({
						maxRecords: 20000,
						pageSize: 40,
						view: payload
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach(function(record) {
								elements.push(record.fields);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								reject(err);
							}
							resolve(elements);
						}
					);
			});
		},
		uploadFile({ state }, payload) {
			return new Promise((resolve, reject) => {
				const data = new FormData();
				data.append("file", payload.file);
				axios({
					method: "POST",
					url: "https://dev-serveur.fr/mailjet/viessmann/" + payload.service + ".php",
					data: data
				})
					.then(res => {
						resolve(res);
					})
					.catch(error => {
						reject(error.message);
					});
			});
		},
		getSuspectsSap({ commit, state }) {
			state.suspects_sap = [];
			return new Promise((resolve, reject) => {
				base("base_suspects")
					.select({
						// Selecting the first 3 records in base_suspects:
						pageSize: 10,
						view: "base_suspects_to_clients"
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach(function(record) {
								commit("addSuspectSAP", record);
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		selectCurrentSuspectSAP({ state }, payload) {
			state.current_suspect_sap = payload.suspect;
		},
		updateClientSAP({ state }) {
			return new Promise((resolve, reject) => {
				if (state.current_suspect_sap.fields.numero_sap.length >= 10) {
					base("base_suspects").update(
						[
							{
								id: state.current_suspect_sap.id,
								fields: {
									numero_sap: state.current_suspect_sap.fields.numero_sap,
									statut_sap: "fait"
								}
							}
						],
						(err, records) => {
							if (err) {
								reject(err);
								alert(err);
								return;
							}
							resolve();
							state.current_suspect_sap.fields.statut_sap = "fait";
						}
					);
				} else {
					base("base_suspects").update(
						[
							{
								id: state.current_suspect_sap.id,
								fields: {
									numero_sap: "",
									statut_sap: "a_faire"
								}
							}
						],
						(err, records) => {
							if (err) {
								reject(err);
								alert(err);
								return;
							}
						}
					);
					state.current_suspect_sap.fields.numero_sap = "";
					state.current_suspect_sap.fields.statut_sap = "a_faire";
				}
			});
		},
		rectificationStatutDepuisSAP({ state }) {
			return new Promise((resolve, reject) => {
				base("base_suspects").update(
					[
						{
							id: state.current_suspect_sap.id,
							fields: {
								statut: state.current_suspect_sap.fields.statut
							}
						}
					],
					(err, records) => {
						if (err) {
							reject(err);
							return;
						}
						resolve();
						if (state.current_suspect_sap.fields.statut != "to_prospect" || state.current_suspect_sap.fields.statut != "to_basic") {
							let found = state.suspects_sap.findIndex(el => el.id === state.current_suspect_sap.id);
							if (found) {
								state.suspects_sap.splice(found, 1);
							}
						}
					}
				);
			});
		},
		generateFicheForSAP({ commit, state }) {
			state.generation_suspect_pdf = true;
			return new Promise((resolve, reject) => {
				const doc = new jsPDF({
					orientation: "p",
					unit: "mm",
					format: "a4",
					putOnlyUsedFonts: true,
					compress: true
				});
				doc.addPage();
				var el0 = document.querySelector("#title_suspect_pdf");
				var el1 = document.querySelector("#suspect-element1");
				var el2 = document.querySelector("#suspect-element2");
				var el3 = document.querySelector("#suspect-element3");
				var el4 = document.querySelector("#suspect-element4");
				var el5 = document.querySelector("#suspect-element5");
				var zonesuspects = document.querySelector(".suspect-sap-details");
				zonesuspects.classList.add("printMode");
				htmlToImage
					.toPng(el0, {
						backgroundColor: "#FFFFFF",
						width: 1215,
						height: 50,
						pixelRatio: 3
					})
					.then(dataUrl => {
						var image = new Image();
						doc.setPage(1);
						doc.addImage(dataUrl, "PNG", 10, 10, 190, 190 * (50 / 1215));
					});
				htmlToImage
					.toPng(el1, {
						backgroundColor: "#FFFFFF",
						width: 460,
						height: 350,
						pixelRatio: 3
					})
					.then(dataUrl => {
						var image = new Image();
						doc.setPage(1);
						doc.addImage(dataUrl, "PNG", 10, 30, 98, 98 * (350 / 460));
						htmlToImage
							.toPng(el3, {
								backgroundColor: "#FFFFFF",
								width: 460,
								height: 290,
								pixelRatio: 3
							})
							.then(dataUrl => {
								var image = new Image();
								doc.setPage(1);
								doc.addImage(dataUrl, "PNG", 10, 110, 98, 98 * (290 / 460));
								htmlToImage
									.toPng(el2, {
										backgroundColor: "#FFFFFF",
										width: 460,
										height: 770,
										pixelRatio: 3
									})
									.then(dataUrl => {
										var image = new Image();
										doc.setPage(1);
										doc.addImage(dataUrl, "PNG", 108, 30, 98, 98 * (770 / 460));
										htmlToImage
											.toPng(el4, {
												backgroundColor: "#FFFFFF",
												width: 460,
												height: 753,
												pixelRatio: 3
											})
											.then(dataUrl => {
												var image = new Image();
												doc.setPage(2);
												doc.addImage(dataUrl, "PNG", 10, 10, 98, 98 * (753 / 460));
												htmlToImage
													.toPng(el5, {
														backgroundColor: "#FFFFFF",
														width: 460,
														height: 1999,
														pixelRatio: 3
													})
													.then(dataUrl => {
														var image = new Image();
														doc.setPage(2);
														doc.addImage(dataUrl, "PNG", 108, 10, 98, 98 * (1999 / 460));
														doc.save(`${state.current_suspect_sap.fields.suspect}.pdf`);
														state.generation_suspect_pdf = false;
														zonesuspects.classList.remove("printMode");
													});
											});
									});
							});
					});
			});
		},
		asyncUpdateStoreField({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				base("clients").update(
					[
						{
							id: state.current_client.id,
							fields: {
								[payload.field]: payload.value
							}
						}
					],
					function(err, records) {
						if (err) {
							reject(err);
						}
						state.current_client = records[0];
						resolve(records[0].fields["Attestation fluide PDF"]);
					}
				);
			});
		},
		askForClassificationChange({ state }, payload) {
			return new Promise((resolve, reject) => {
				base("demandes_classification").create(
					[
						{
							fields: {
								id_demande: new Date(),
								id_client: [payload.id_client],
								motif_changement: payload.motif_changement,
								nouveau_statut: payload.nouveau_statut,
								utilisateurs: [payload.utilisateur]
							}
						}
					],
					function(err, records) {
						if (err) {
							reject(err);
						}
						resolve(records);
					}
				);
			});
		},
		getValidations({ state }) {
			return new Promise((resolve, reject) => {
				if (state.user.id && state.user.fields.role === "administrateur") {
					state.validations = [];
					base("demandes_classification")
						.select({
							view: "demandes",
							maxRecords: 999
						})
						.eachPage(
							function page(records, fetchNextPage) {
								// This function (`page`) will get called for each page of records.
								state.validations = [...state.validations, ...records];
								// To fetch the next page of records, call `fetchNextPage`.
								// If there are more records, `page` will get called again.
								// If there are no more records, `done` will get called.
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									reject(err);
									return;
								}
								resolve();
							}
						);
				} else {
					reject("Vous n'avez pas les droits suffisants");
				}
			});
		},
		refuseChangement({ state, dispatch }, payload) {
			if (state.user.id && state.user.fields.role === "administrateur") {
				base("demandes_classification").destroy([payload], function(err, deletedRecords) {
					if (err) {
						Notify({
							type: "danger",
							message: "Quelque chose s'est mal passé"
						});
						return;
					}
					Notify({
						type: "success",
						message: "La demande a bien été refusée."
					});
					dispatch("getValidations");
				});
			}
		},
		acceptChangement({ state, dispatch }, payload) {
			if (state.user.id && state.user.fields.role === "administrateur") {
				let fields;
				if (payload.nouveau === "Black") {
					fields = {
						"Classification (Matrice)": "Black",
						"Classe de partenaire": "Proactif Platinium (MI+)",
						"Objectif visites": 16
					};
				} else if (payload.nouveau === "Gold") {
					fields = {
						"Classification (Matrice)": "Gold",
						"Classe de partenaire": "Proactif Gold (MIP)",
						"Objectif visites": 12
					};
				} else if (payload.nouveau === "Silver") {
					fields = {
						"Classification (Matrice)": "Silver",
						"Classe de partenaire": "Partenaire (MAP)",
						"Objectif visites": 8
					};
				} else if (payload.nouveau === "Basic") {
					fields = {
						"Classification (Matrice)": "Basic",
						"Classe de partenaire": "Basic (PAR)",
						"Objectif visites": 0
					};
				}
				// Je modifie le client dans l'app

				// Je modifie le client dans la base
				base("clients").update(
					[
						{
							id: payload.client,
							fields: fields
						}
					],
					function(err, records) {
						if (err) {
							Notify({
								type: "danger",
								message: err
							});
							return;
						}
						Notify({
							type: "success",
							message: "La demande a bien été accepté et le statut vient d'être changé."
						});
						base("demandes_classification").destroy([payload.id], function(err, deletedRecords) {
							if (err) {
								Notify({
									type: "danger",
									message: "Quelque chose s'est mal passé"
								});
								return;
							}
							dispatch("getValidations");
						});
					}
				);
			}
		},
		updateSilverToMIP({ state, commit }, payload) {
			Dialog.confirm({
				title: "Confirmation",
				message: "En validant, vous allez modifier la classification de Partenaire (MAP) vers Proactif Gold (MIP). Cette action est irréversible.",
				confirmButtonText: "Je confirme",
				cancelButtonText: "Annuler",
				cancelButtonColor: "#000000",
				confirmButtonColor: "#0288d1"
			})
				.then(() => {
					console.log(payload);
					// Je déduis une Wilcard du compte de l'utilisateur
					state.user.fields.wild_card--;
					commit("updateUser", {
						field: "wild_card",
						value: state.user.fields.wild_card
					});
					console.log("User mis à jour");

					// J'update le client dans le store et Airtable
					let current_client = state.clients.find(el => el.id === payload);
					current_client.fields["Classe de partenaire"] = "Proactif Gold (MIP)";
					base("clients").update(
						[
							{
								id: payload,
								fields: {
									"Classe de partenaire": "Proactif Gold (MIP)",
									"Objectif visites": 12,
									"Wild Card Proactif": true
								}
							}
						],
						function(err, records) {
							if (err) {
								return;
							}
							console.log("Client mis à jour");
							Notify({
								type: "success",
								message: "Le changement a bien été réalisé. Merci. "
							});
						}
					);
					// Je change le statut en Proactif et je change le nombre de visite
				})
				.catch(() => {
					//
				});
		},
		CGV_getClients({ state, commit, dispatch }) {
			state.CGV_clients = [];
			return new Promise((resolve, reject) => {
				let secteur = state.user.fields.secteur;
				if (state.user.fields.role != "atc" && state.current_secteur_client.length > 0) {
					secteur = state.current_secteur_client;
				}
				base("clients_cgv")
					.select({
						maxRecords: 9999,
						view: "tous",
						pageSize: 40,
						filterByFormula: `({secteur} = "${secteur}")`,
						sort: [{ field: "societe", direction: "asc" }]
					})
					.eachPage(
						function page(records, fetchNextPage) {
							records.forEach(function(record) {
								record.isSending = false;
								if (record.fields.statut === "En cours de signature" && record.fields.id_procedure_signature) {
									dispatch("checkSignatureV3", record.fields["id_procedure_signature"])
										.then(res => {
											if (res.status === "done") {
												record.fields.statut = "Signé";
												base("clients_cgv").update([
													{
														id: record.id,
														fields: {
															statut: "Signé"
														}
													}
												]);
												if (!record.fields.url_pdf_signe) {
													// dispatch("downloadSignedDocumentCGV", {
													// 	procedure: record.fields.id_procedure_signature,
													// 	client: record
													// });
												}
											}
											if (res.status === "expired" || res.status === "rejected" || res.status === "declined") {
												record.fields.statut = "A envoyer";
											}
											commit("addCGVClient", record);
										})
										.catch(err => {
											commit("addCGVClient", record);
										});
								} else {
									if (record.fields.statut === "Signé" && !record.fields.url_pdf_signe) {
										// dispatch("downloadSignedDocumentCGV", {
										// 	procedure: record.fields["id_procedure_signature"],
										// 	client: record
										// });
									}
									commit("addCGVClient", record);
								}
							});
							fetchNextPage();
						},
						function done(err) {
							if (err) {
								reject(err);
								return;
							}
							resolve();
						}
					);
			});
		},
		CGV_createClient({ state, commit }, payload) {
			return new Promise((resolve, reject) => {
				base("clients_cgv").create(
					[
						{
							fields: {
								...payload
							}
						}
					],
					function(err, records) {
						if (err) {
							reject(err);
							return;
						}
						let record = records[0];
						record.isSending = false;
						commit("addCGVClient", record);
						resolve(record);
					}
				);
			});
		},
		async beginSignatureV3({ commit, state }, payload) {
			try {
				// Création de la signature
				state.message_generation = "Création de la procédure de signature...";
				let response, requestBodyPayload;
				requestBodyPayload = {
					name: `PAC Viessmann 2025 : ${payload.numero} - ${payload.nom_client}`,
					delivery_mode: "email",
					custom_experience_id: "d09f6fed-1f8d-4cd5-adc9-1bcc0cd9e5df",
					timezone: "Europe/Paris",
					expiration_date: "2025-04-13"
				};

				response = await fetch(`${base_api_url}/signature_requests`, {
					method: "POST",
					headers,
					body: JSON.stringify(requestBodyPayload)
				});

				const signatureRequest = await response.json();

				// Ajout du document placé dans public

				// Ajout du document
				state.message_generation = "Envoi du document à signer...";
				const form = new FormData();
				const pdfBlob = payload.doc.output("blob");
				form.append("file", pdfBlob, `pac_2025_${payload.numero}.pdf`);
				form.append("nature", "signable_document");

				response = await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/documents`, {
					method: "POST",
					headers: {
						authorization: `Bearer ${apiKey}`
					},
					body: form
				});

				const document = await response.json();
				

				// Ajout du second document (CGV)
				state.message_generation = "Ajout du second document (CGV)...";

				const cgvResponse = await fetch('/cgv.pdf'); // Charge le PDF depuis public/
				if (!cgvResponse.ok) throw new Error("Erreur lors du chargement du document CGV");

				const cgvBlob = await cgvResponse.blob();
				const cgvForm = new FormData();
				cgvForm.append("file", cgvBlob, "conditions_generales_de_vente_viessmann.pdf"); // Nom du fichier
				cgvForm.append("nature", "signable_document"); // Type de document

				response = await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/documents`, {
					method: "POST",
					headers: {
						authorization: `Bearer ${apiKey}`
					},
					body: cgvForm
				});

				const cgvDocument = await response.json();

				// Ajout du signataire
				state.message_generation = "Création des coordonnées pour la signature...";
				requestBodyPayload = {
					info: {
						first_name: state.current_client.fields["Prénom signataire"].trim(),
						last_name: state.current_client.fields["Nom signataire"].trim(),
						email: state.current_client.fields["Email signataire"],
						phone_number: state.current_client.fields["Tél signataire"],
						locale: "fr"
					},
					signature_authentication_mode: "no_otp",
					signature_level: "electronic_signature",
					fields: [
						{
							document_id: document.id,
							type: "signature",
							height: 63,
							width: 254,
							page: 4,
							x: 299,
							y: 685
						}
					]
				};

				response = await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/signers`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
						authorization: `Bearer ${apiKey}`
					},
					body: JSON.stringify(requestBodyPayload)
				});

				const signer = await response.json();

				// Ajout du consentement
				state.message_generation = "Ajout du consentement...";
				response = await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/consent_requests`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						authorization: `Bearer ${apiKey}`
					},
					body: JSON.stringify({
						type: "checkbox",
						settings: {
							text: "J'ai pris connaissance et j'accepte les conditions générales de vente et de garantie VIESSMANN",
						},
						signer_ids: [signer.id],
						optional: false,
						document_id: cgvDocument.id
					})
				});
		    
				const consentRequest = await response.json();
		    
				// Association du consentement au signataire
				state.message_generation = "Association du consentement au signataire...";
				await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/consent_requests/${consentRequest.id}/signers/${signer.id}`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						authorization: `Bearer ${apiKey}`
					}
				});

				state.message_generation = "Validation de la procédure et envoi des notifications...";
				// Envoi de la notification de signature
				await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/activate`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
						authorization: `Bearer ${apiKey}`
					}
				});

				commit("updateClientStatus", {
					client: state.current_client.id,
					statut: "Envoyé"
				});
				commit("updateStoreField", {
					value: signatureRequest.id,
					field: "id_procedure_signature"
				});
				commit("updateStoreField", {
					value: `${viewer_url}${signatureRequest.id}`,
					field: "lien_signature"
				});
				commit("updateStoreField", {
					value: "Envoyé",
					field: "statut"
				});
				commit("updateStoreField", {
					value: version,
					field: "app_version"
				});
				return signatureRequest;
			} catch (e) {
				return e;
			}
		}
	},
	getters: {
		getSecteurs: state => {
			return state.secteurs;
		},
		generation_suspect_pdf: state => {
			return state.generation_suspect_pdf;
		},
		suspectsSAP: state => {
			return state.suspects_sap;
		},
		suspectsSAPdone: state => {
			let done = state.suspects_sap.filter(el => el.fields.statut_sap === "fait");
			return done;
		},
		suspectsSAPtodo: state => {
			let todo = state.suspects_sap.filter(el => el.fields.statut_sap === "a_faire");
			return todo;
		},
		currentSuspectSAP: state => {
			if (state.current_suspect_sap.id) {
				state.current_suspect_sap.fields.statut_sap_review = "À faire";
				if (state.current_suspect_sap.fields.statut_sap === "fait") {
					state.current_suspect_sap.fields.statut_sap_review = "Fait";
				}
			}
			return state.current_suspect_sap;
		},
		currentClient: state => {
			return state.current_client;
		},
		allClients: state => {
			state.clients.forEach(el => {
				if (el.fields.statut === "Brouillon") {
					el.fields.statut_format = "brouillon";
				} else if (el.fields.statut === "En attente de validation") {
					el.fields.statut_format = "validation";
				} else if (el.fields.statut === "Prêt à signer") {
					el.fields.statut_format = "pret";
				} else if (el.fields.statut === "Envoyé") {
					el.fields.statut_format = "envoye";
				} else if (el.fields.statut === "Signé") {
					el.fields.statut_format = "signe";
				} else if (el.fields.statut === "Refusé") {
					el.fields.statut_format = "refuse";
				}
			});
			return state.clients;
		},
		fetchStatus: state => {
			return state.fetch_client_ended;
		},
		message: state => {
			if (state.message.length > 0) {
				return state.message[0].fields;
			}
		},
		validations: state => {
			return state.validations;
		},
		getUser: state => {
			return state.user;
		},
		statistiques: state => {
			let tab_dr = state.stats.sort(function(a, b) {
				return a.dr.localeCompare(b.dr) || b.rrv - a.rrv;
			});
		},
		super_stats: state => {
			console.log(state.super_stats);
			return state.super_stats;
		},
		secteur_encours: state => {
			return state.stats_current_secteur;
		},
		stats_chargement: state => {
			return state.stats_ready;
		}
	},
	modules: {},
	plugins: [vuexLocal.plugin]
});
